<template>
  <label class="switcher" :class="{ 'switcher--active': value, 'switcher--readonly': readonly }">
    <input
      :checked="value"
      @change="$emit('change', $event.target.checked)"
      class="switcher__input"
      type="checkbox"
    >
    <span class="switcher__view">
      <span class="switcher__body"></span>
      <span class="switcher__btn"></span>
    </span>
  </label>
</template>

<script>
export default {
  name: 'AppSwitcher',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style lang="sass" scoped>
.switcher
  position: relative

.switcher--active
  .switcher__body
    background-color: $color-theme

  .switcher__btn
    left: 18px

  .switcher__value
    color: #38bf4e

.switcher__input
  +visually-hidden

.switcher__view
  position: relative
  display: block
  width: 40px
  height: 24px
  cursor: pointer

.switcher__body
  display: block
  width: 100%
  height: 100%
  border-radius: 20px
  background-color: #efefef
  overflow: hidden
  transition: background-color 0.3s, border-color 0.3s

.switcher__btn
  position: absolute
  top: 50%
  left: 3px
  width: 19px
  height: 19px
  background-color: #fff
  border-radius: 50%
  transform: translateY(-50%)
  transition: background-color 0.3s, left 0.3s

.switcher__value
  color: $color-error

.switcher--readonly
  pointer-events: none

  &.switcher--active
    .switcher__body
      background-color: #ff9416

  .switcher__btn
    border: 1px solid #ff9416
</style>
